@media screen and (min-width: 700px) {
    .immagine {
        max-height: 550px;
    }
}

@media screen and (max-width: 700px) and (min-width:340px) {
    .immagine {
        max-height: 185px;
    }
}

@media (max-width:340px) {
    .immagine {
        max-height: 160px !important;
    }
}

h1 {
    text-align: center;
}

.Left {
    width: 220px;
    height: 300px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    flex: 1;
    border: 1px solid black;
    display: flex;
    align-items: center;
}

.Right {
    width: 220px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    flex: 1;
    border: 1px solid black;
    display: flex;
    align-items: center;
}