.LinkTitleActive {
    color: black;
    text-decoration: none;
    font-size: large;
    padding-right: 10px;
    padding-top: 1px;
}

.LinkTitleDisable {
    color: gray;
    text-decoration: none;
    font-size: large;
    padding-right: 10px;
    padding-top: 1px;
}

.LinkSubElementActive {
    color: black;
    text-decoration: none;
    font-size: medium;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.LinkSubElementDisable {
    color: gray;
    text-decoration: none;
    font-size: medium;
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
}

.graficaInformazioni {
    text-align: left;
    padding-left: 75px !important;
    background-color: rgb(198, 225, 243);
}